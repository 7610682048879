import { CollageWithTextStoryblok } from '../storyblok.generated'
import CollageWithText from '../../patterns/CollageWithText'
import RichText from '../../components/RichText'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'

interface Props {
  content: CollageWithTextStoryblok
}

const CollageWithTextBlok = ({
  content: {
    title,
    overline,
    description,
    imageLayoutVariant,
    imageOne,
    imageTwo,
    buttonLabel,
    buttonLink,
  },
  ...others
}: Props) => (
  <CollageWithText
    title={title}
    overline={overline}
    description={
      typeof description === 'object' ? (
        <RichText as="span">{description}</RichText>
      ) : (
        description
      )
    }
    imageLayoutVariant={imageLayoutVariant}
    imageOne={{
      src: imageOne.filename,
      alt: imageOne.alt,
    }}
    imageTwo={{
      src: imageTwo.filename,
      alt: imageTwo.alt,
    }}
    buttonLabel={buttonLabel}
    buttonLink={buttonLink ? storyblokLinkResolver(buttonLink) : undefined}
    {...others}
  />
)

export default CollageWithTextBlok
