import FooterBlok from '../FooterBlok'
import HeaderBlok from '../HeaderBlok'
import {
  ArticleStoryblok,
  EventStoryblok,
  SiteStoryblok,
} from '../storyblok.generated'
import createSeoHeadParams from '../../utils/createSeoHeadParams'
import { StoryblokStory, StoryblokStoryResponse } from '../../utils/storyblok'
import Article from '../../components/Article'
import Head from '../../components/Head'
import BlokZone from '../../components/BlokZone'
import patterns from '../patterns'
import { PageContextProvider } from '../PageContext'
import visibleNonBreakingSpace from '../../utils/visibleNonBreakingSpace'
import { useStory } from '../../utils/useStory'
import { Site, currentSite } from '../../sites'
import { mapImage } from '../../utils/mapImage'
import { mapVideo } from '../../utils/mapVideo'
import { useCurrentHostname } from '../../utils/getHostNameForSite'

export const serializePublicationDate = (publicationDate: string) => {
  // Older Safari browsers cannot handle a '-' in the date, this must be a '/'
  const date = new Date(publicationDate.replace(/-/g, '/'))
  if (date.getUTCHours() === 0) {
    // Set to 12 o'clock so that the shown date is the same in nearly all timezones
    date.setUTCHours(12)
  }
  return date.toISOString()
}

interface Props {
  siteStory: StoryblokStoryResponse<StoryblokStory<SiteStoryblok>>
  story: StoryblokStoryResponse<
    StoryblokStory<ArticleStoryblok | EventStoryblok>
  >
  slug: string
  overridenSite?: Site
}

const ArticlePage = ({
  story: pageStory,
  siteStory,
  slug,
  overridenSite,
}: Props) => {
  const page = useStory(pageStory.story)
  const site = useStory(siteStory.story)
  const { hostName, getHostNameForSite } = useCurrentHostname()

  const { defaultSocialShareTitle } = site.content
  const hasSocialShare = page.content.contentBlocks?.some(
    (item) => item.component === 'socialShare',
  )

  const publisedAt =
    page.content.component === 'article' && page.content.publicationDate
      ? serializePublicationDate(page.content.publicationDate)
      : page.first_published_at || page.created_at

  return (
    <PageContextProvider
      value={{
        rels: pageStory.rels,
        links: pageStory.links,
      }}
    >
      <Head
        title={visibleNonBreakingSpace(page.content.title)}
        {...createSeoHeadParams(
          page.content,
          slug,
          overridenSite ? getHostNameForSite(overridenSite) : hostName,
        )}
      />

      <HeaderBlok
        siteStory={site.content}
        variant={currentSite === Site.Amels ? 'dark' : 'light'}
      />

      <Article
        isEvent={page.content.component === 'event'}
        title={page.content.title}
        type={page.content.type}
        publishedAt={publisedAt}
        image={mapImage(page.content.image)}
        video={mapVideo(page.content.video)?.src}
        defaultSocialShareTitle={defaultSocialShareTitle}
        socialShare={!hasSocialShare}
        dateShort={
          page.content.component === 'event'
            ? page.content.dateShort
            : undefined
        }
        location={
          page.content.component === 'event' ? page.content.location : undefined
        }
      >
        <BlokZone
          components={patterns}
          content={page.content.contentBlocks || []}
        />
      </Article>

      <FooterBlok content={site.content.footerBlock[0]} />
    </PageContextProvider>
  )
}

export default ArticlePage
