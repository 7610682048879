import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../../components/AnimateText'
import Image from '../../components/Image'
import Section from '../../components/Section'

import type { TextWithImageProps } from './index'
import Link from '../../components/Link'
import {
  secondaryOutlineButtonCssXplorer,
  secondaryOutlineInvertedButtonCssXplorer,
} from '../../components/Button'

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) =>
    prop !== 'imageLocation' && prop !== 'backgroundImage',
})<{
  imageLocation: TextWithImageProps['imageLocation']
  backgroundImage: TextWithImageProps['backgroundImage']
}>(({ imageLocation, backgroundImage }) => [
  css`
    width: 100%;
    height: auto;
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    backgroundImage &&
    css`
      z-index: 1;
    `,
])
const ImageContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'imageLocation' && prop !== 'backgroundImage',
})<{
  imageLocation: TextWithImageProps['imageLocation']
  backgroundImage: TextWithImageProps['backgroundImage']
}>(({ theme, imageLocation, backgroundImage }) => [
  css`
    position: relative;
    display: flex;
    flex: 0 1 auto;
    overflow: hidden;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
      flex-direction: column;
      align-items: center;
      max-height: 540px;

      ${StyledImage} {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    backgroundImage &&
    css`
      overflow: visible;
    `,
])
const LogoContainer = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x8}px;
    }
  `,
)

const Overline = styled('span')(({ theme }) => [
  css`
    ${theme.text.overlineSmall && theme.text.overlineSmall(theme)}
    display: block;
    margin-bottom: ${theme.spacing.x2}px;
    color: ${theme.colors.xplorer.secondaryXPBronze};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }
  `,
])
const Title = styled('h2')(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    margin: 0 0 ${theme.spacing.x6}px;
  `,
])
const Text = styled.div(
  ({ theme }) =>
    css`
      ${theme.text.body(theme)}
      color: ${theme.colors.xplorer.primaryXPLavaBlack};

      p {
        margin: 0;
        text-align: center;
      }
    `,
)
const Content = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    ${Overline},
    ${Title} {
      text-align: center;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 480px;
      width: 100%;
    }
  `,
)
const StyledBackgroundImage = styled(Image)(
  ({ theme }) => css`
    position: absolute;
    top: 50%;

    width: 100%;
    height: auto;
    transform: translateY(-50%);

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      left: -30%;
      width: 160%;
    }
  `,
)
const ContentContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'imageLocation',
})<{
  imageLocation: TextWithImageProps['imageLocation']
}>(({ theme, imageLocation }) => [
  css`
    ${theme.layout.maxContentWidth(theme)};
    display: flex;
    flex-direction: ${imageLocation === 'left' ? 'column' : 'column-reverse'};
    position: relative;
    gap: ${theme.spacing.x4}px;
    justify-content: center;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x10}px;
      flex-direction: row;
    }
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    css`
      align-items: stretch;
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        flex-flow: ${imageLocation === 'left' ? 'row' : 'row-reverse'};
      }
    `,
])

const StyledLink = styled(Link)(({ theme }) => [
  css`
    ${secondaryOutlineButtonCssXplorer(theme)}

    :hover {
      text-decoration: none;
    }

    p {
      margin: 0;
    }
  `,
])

const Container = styled(Section, {
  shouldForwardProp: (prop) =>
    prop !== 'imageLocation' && prop !== 'backgroundImage',
})<{
  background: TextWithImageProps['background']
}>(({ theme, background }) => [
  css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
    z-index: 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: ${theme.spacing.x15}px;
    }
  `,
  background === 'white' &&
    css`
      background: ${theme.colors.xplorer.white};
    `,
  background === 'lightGreen' &&
    css`
      background: ${theme.colors.xplorer.secondaryXPMistGrey};
    `,
  background === 'darkGreen' &&
    css`
      background: ${theme.colors.xplorer.primaryXPDarkOceanGreen};

      ${Title},
      ${Text} {
        color: ${theme.colors.xplorer.white};
      }

      ${StyledLink} {
        ${secondaryOutlineInvertedButtonCssXplorer(theme)}
      }
    `,
])

const StyledLinkWrapper = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: ${theme.spacing.x6}px;
    }
  `,
)

const TextWithImage = ({
  overline,
  title,
  text,
  image,
  imageLocation = 'right',
  background,
  backgroundImage,
  logo,
  link,
  triggerFormCallToAction,
  ...others
}: TextWithImageProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  const imageOrientation =
    imageLocation === 'left' || imageLocation === 'right'
      ? 'portrait'
      : 'landscape'

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      background={background}
      data-testid="textWithImage"
      ref={containerRef}
      {...others}
    >
      <ContentContainer imageLocation={imageLocation}>
        <ImageContainer
          imageLocation={imageLocation}
          backgroundImage={backgroundImage}
        >
          {imageOrientation === 'portrait' && (
            <StyledImage
              src={image.src}
              alt={image.alt}
              width={585}
              height={780}
              aspectRatio="585:780"
              style={{ objectFit: 'cover' }}
              imageLocation={imageLocation}
              backgroundImage={backgroundImage}
            />
          )}
          {imageOrientation === 'landscape' && (
            <StyledImage
              src={image.src}
              alt={image.alt}
              width={1200}
              height={800}
              aspectRatio="1200:800"
              style={{ objectFit: 'cover' }}
              imageLocation={imageLocation}
              backgroundImage={backgroundImage}
            />
          )}
          {(imageLocation === 'left' || imageLocation === 'right') &&
            backgroundImage && (
              <StyledBackgroundImage
                src={backgroundImage.src}
                alt={backgroundImage.alt ?? ''}
                width={1200}
                height={800}
                aspectRatio="1200:800"
                style={{ objectFit: 'cover' }}
              />
            )}
        </ImageContainer>
        <Content>
          {logo?.src ? (
            <AnimateText>
              <LogoContainer>
                <Image
                  src={logo.src}
                  alt={logo.alt ?? ''}
                  width={185}
                  height={170}
                  aspectRatio="185:170"
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    maxWidth: '185px',
                  }}
                />
              </LogoContainer>
            </AnimateText>
          ) : (
            <AnimateText>
              {overline && <Overline>{overline}</Overline>}
              <Title>{title}</Title>
            </AnimateText>
          )}
          <AnimateText delay={400}>
            <Text>{text}</Text>
          </AnimateText>
          <StyledLinkWrapper>
            {triggerFormCallToAction || (
              <AnimateText delay={800}>
                {link && (
                  <StyledLink href={link.href} target={link?.target}>
                    {link.title}
                  </StyledLink>
                )}
              </AnimateText>
            )}
          </StyledLinkWrapper>
        </Content>
      </ContentContainer>
    </Container>
  )
}

export default TextWithImage
