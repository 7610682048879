import React, { ComponentProps } from 'react'
import Link from 'next/link'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'
import { SitemapHtmlLink } from '../utils/storyBlokSitemapHtmlBuilder'
import { isSiteAmels, isSiteYachting } from '../themes'

const Container = styled(Section)(({ theme }) => [
  css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x5}px;
    margin-bottom: ${theme.spacing.x2}px;

    ul {
      margin-block-start: ${theme.spacing.x1}px;
      padding-inline-start: ${theme.spacing.x5}px;
    }

    ul ul {
      margin-block-start: ${theme.spacing.x1}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x5}px;
    }
  `,
  isSiteAmels(theme) &&
    css`
      li::marker {
        color: ${theme.colors.amels.deepBayAqua};
      }
    `,
  isSiteYachting(theme) &&
    css`
      li::marker {
        color: ${theme.colors.yachting.darkBlue};
      }
    `,
])

const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.layout.maxContentWidth(theme)};
  `,
)

const ListItem = styled.li(
  ({ theme }) => css`
    padding: ${theme.spacing.x1}px 0;
  `,
)

export interface SitemapTreeProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant' | 'title'
  > {
  sectionTitle?: string
  items: SitemapHtmlLink[]
}

const mapSitemapList = (SitemapItem: SitemapHtmlLink): React.ReactNode => {
  return (
    <ul>
      {SitemapItem.items.map((item: SitemapHtmlLink) => (
        <ListItem key={item.id}>
          {item.link && <Link href={item.link.url}>{item.link.text}</Link>}
          {item.items?.length > 0 && mapSitemapList(item)}
        </ListItem>
      ))}
    </ul>
  )
}

const SitemapTree = ({ items, ...others }: SitemapTreeProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="sitemapTree"
      ref={containerRef}
      {...others}
    >
      <Wrapper>
        {items?.map((sitemapItem: SitemapHtmlLink) => {
          return (
            <React.Fragment key={sitemapItem.id}>
              {sitemapItem.link && (
                <Link href={sitemapItem.link.url}>{sitemapItem.link.text}</Link>
              )}
              {sitemapItem.items?.length > 0 && mapSitemapList(sitemapItem)}
            </React.Fragment>
          )
        })}
      </Wrapper>
    </Container>
  )
}

export default SitemapTree
