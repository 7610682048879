import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { VideoFeedProps } from './index'
import {
  bodyLarge,
  heading2,
  overLineMedium,
  overLineSmall,
} from '../../themes/yachtsupport/text'
import AnimateText from '../../components/AnimateText'
import Image from '../../components/Image'
import Link from '../../components/Link'

const Container = styled.section(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing.x6}px ${theme.spacing.x3}px;
      gap: ${theme.spacing.x6}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;
        gap: ${theme.spacing.x8}px;
      }
    `,
)

const Content = styled.div(
  () =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1000px;
      margin: 0 auto;
      text-align: center;
    `,
)

const Heading = styled.div(
  ({ theme }) =>
    css`
      ${heading2(theme)}
      margin-top: 0;
      margin-bottom: ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x5}px;
      }
    `,
)

const StyledHeading = ({ heading }: { heading: string }) => {
  const delimiter = '—'
  const lines = heading.split(delimiter)
  const lineAfterDelimiter = lines.pop()
  const LineAfterDelimiterSpan = styled.span(
    ({ theme }) =>
      css`
        color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      `,
  )

  return (
    <Heading>
      {lines.length === 0 && heading}
      {lines.length > 0 && lines + delimiter}
      {lines.length > 0 && (
        <LineAfterDelimiterSpan>{lineAfterDelimiter}</LineAfterDelimiterSpan>
      )}
    </Heading>
  )
}

const Overline = styled.div(
  ({ theme }) =>
    css`
      ${overLineSmall(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        ${overLineMedium(theme)}
        color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
)

const VideosInnerWrapper = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: ${theme.spacing.x6}px;
      max-width: 1376px;
      margin: 0 auto ${theme.spacing.x6}px auto;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        flex-direction: row;
        gap: ${theme.spacing.x4}px;
        margin: 0 auto ${theme.spacing.x8}px auto;
      }
    `,
)

const VideoImageContainer = styled('div')(
  () => css`
    overflow: hidden;
    position: relative;
  `,
)

const VideoTitle = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading3(theme)}
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    margin: 0;
    transition: linear 200ms;
  `,
])

const VideoDescription = styled.p(({ theme }) => [
  css`
    ${theme.text.body(theme)}
    color: ${theme.colors.yachtSupport.secondaryYSWarmGrey};
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
  `,
  css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-weight: 400;
    }
  `,
])

const WantToKnowMoreCardLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    text-transform: uppercase;
    padding: 12px ${theme.spacing.x3}px;
    border: 1px solid ${theme.colors.yachtSupport.grey03};

    :hover {
      background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      color: ${theme.colors.yachtSupport.white};
      text-decoration: none;
      border: 1px solid
        ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    }
  `,
)

const VideoLink = styled(Link)(
  ({ theme }) =>
    css`
      display: block;
      height: 100%;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        :nth-of-type(even) {
          position: relative;
          top: ${theme.spacing.x10}px;
        }
      }

      > div {
        height: 100%;
      }

      img {
        transition: transform ease-out 3000ms;
      }

      :hover {
        text-decoration: none;

        img {
          transform: scale(1.2);
        }

        ${VideoImageContainer} {
          filter: none;
        }

        ${VideoTitle} {
          color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
        }

        button {
          background-color: ${theme.colors.yachtSupport.grey01};
        }
      }
    `,
)

const WantToKnowMoreCard = styled.div(
  ({ theme }) => css`
    background: var(
      --Lineair-Blue,
      linear-gradient(
        45deg,
        ${theme.colors.yachtSupport.primaryYSCarbonBlack} 0%,
        #004771 97.6%
      )
    );
    padding: ${theme.spacing.x9}px ${theme.spacing.x7}px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: ${theme.spacing.x4}px;
    position: relative;
    top: ${theme.spacing.x10}px;

    .heading {
      ${theme.text.heading2(theme)}
      color: ${theme.colors.yachtSupport.white};
    }
    .description {
      ${bodyLarge(theme)}
      color: ${theme.colors.yachtSupport.white};
    }
  `,
)

const Videos = styled.div<{ numberOfVideos: number }>(
  ({ theme, numberOfVideos }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.spacing.x4}px;

    ${VideoLink}:nth-of-type(n + 5) {
      display: none;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: 1fr 1fr;

      ${numberOfVideos === 1 || numberOfVideos === 3
        ? `
      ${WantToKnowMoreCard} {
        display: flex;
      }`
        : ''}
    }
  `,
)

const Video = styled.article(
  () => css`
    display: flex;
    flex-flow: column;
    height: 100%;
  `,
)

const VideoImage = styled(Image)(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: auto;
    max-height: 211px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-height: 378px;
    }
  `,
)

const VideoContent = styled.div(({ theme }) => [
  css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2}px;
    padding: ${theme.spacing.x3}px;
    border: 1px solid var(--Grey-03, ${theme.colors.yachtSupport.grey03});
  `,
])

const VideoButton = styled('span')(
  ({ theme }) => css`
    width: fit-content;
    color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    border: 1px solid ${theme.colors.yachtSupport.grey03};
    padding: 12px ${theme.spacing.x3}px;
    margin-top: ${theme.spacing.x3}px;

    :before {
      display: none;
    }

    :hover {
      color: ${theme.colors.yachtSupport.white};
      text-decoration: none;
      border: 1px solid
        ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    }
  `,
)

const VideoInfo = styled.div(({ theme }) => [
  css`
    display: inline-block;
  `,
  css`
    ${theme.text.body(theme)}
    font-size: 12px;
    color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    position: relative;
    text-transform: uppercase;
  `,
])

const VideoFeed = (props: VideoFeedProps) => {
  const { heading, overline, videos, wantToKnowMore, ...others } = props

  return (
    <Container {...others} data-testid="videoFeed">
      <Content>
        {overline && <Overline>{overline}</Overline>}
        <StyledHeading heading={heading} />
      </Content>
      <VideosInnerWrapper>
        <Videos numberOfVideos={videos.length}>
          {videos.map(
            (
              { key, title, image, summary, caption, link, buttonLabel },
              index,
            ) => (
              <VideoLink key={key} href={link}>
                <AnimateText delay={1200 + index * 600}>
                  <Video>
                    <VideoImageContainer>
                      <VideoImage
                        src={image}
                        alt={title}
                        width={672}
                        height={378}
                        aspectRatio="672:378"
                        style={{ objectFit: 'cover' }}
                      />
                    </VideoImageContainer>
                    <VideoContent>
                      {caption && <VideoInfo>{caption}</VideoInfo>}
                      <VideoTitle>{title}</VideoTitle>
                      <VideoDescription>{summary}</VideoDescription>
                      <VideoButton>{buttonLabel}</VideoButton>
                    </VideoContent>
                  </Video>
                </AnimateText>
              </VideoLink>
            ),
          )}
          {wantToKnowMore && (
            <WantToKnowMoreCard>
              <span className="heading">{wantToKnowMore.heading}</span>
              <span className="description">{wantToKnowMore.description}</span>
              <WantToKnowMoreCardLink href={wantToKnowMore.link}>
                {wantToKnowMore.buttonLabel}
              </WantToKnowMoreCardLink>
            </WantToKnowMoreCard>
          )}
        </Videos>
      </VideosInnerWrapper>
    </Container>
  )
}

export default VideoFeed
