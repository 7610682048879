import LogoBar from '../../patterns/LogoBar'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { LogoBarStoryblok } from '../storyblok.generated'

interface Props {
  content: LogoBarStoryblok
}

const LogoBarBlok = ({
  content: { logos, logosWithLink },
  ...others
}: Props) => (
  <LogoBar
    logos={logos?.map((image) => {
      const mappedImage = mapImage(image) ?? getRequiredImageFallback()
      return {
        key: image.id.toString(),
        src: mappedImage.src,
        alt: mappedImage.alt,
      }
    })}
    items={logosWithLink?.map(({ image, linkBlock }) => {
      const mappedImage = mapImage(image) ?? getRequiredImageFallback()
      return {
        key: String(image.id),
        src: mappedImage.src,
        alt: mappedImage.alt,
        link: linkBlock?.[0]
          ? {
              title: linkBlock[0].title,
              href:
                linkBlock[0].component === 'linkWithTitle'
                  ? // eslint-disable-next-line security/detect-non-literal-fs-filename
                    storyblokLinkResolver(linkBlock[0].link)
                  : undefined,
              target: linkBlock[0].linkTarget,
            }
          : undefined,
      }
    })}
    {...others}
  />
)

export default LogoBarBlok
