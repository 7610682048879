import DesignTextWithCarousel from '../../patterns/DesignTextWithCarousel'
import RichText from '../../components/RichText'
import slugify from '../../utils/slugify'
import { DesignTextWithCarouselStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: DesignTextWithCarouselStoryblok
}

const DesignTextWithCarouselBlok = ({
  content: {
    sectionTitle,
    title,
    subTitle,
    text,
    carouselImages,
    carouselLocation,
  },
  ...others
}: Props) => (
  <DesignTextWithCarousel
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    text={<RichText>{text}</RichText>}
    carouselImages={carouselImages.map((image) => {
      const mappedImage = mapImage(image) ?? getRequiredImageFallback()
      return {
        key: image.id.toString(),
        image: mappedImage,
      }
    })}
    carouselLocation={carouselLocation}
    {...others}
  />
)

export default DesignTextWithCarouselBlok
