import TabbedCarousel from '../../patterns/TabbedCarousel'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import slugify from '../../utils/slugify'
import { TabbedCarouselStoryblok } from '../storyblok.generated'

interface Props {
  content: TabbedCarouselStoryblok
}

const TabbedCarouselBlok = ({
  content: { sectionTitle, tabBlocks },
  ...others
}: Props) => (
  <TabbedCarousel
    id={slugify(sectionTitle)}
    tabs={tabBlocks.map(({ _uid, title, images }) => ({
      key: _uid,
      title,
      images: images.map((image) => {
        const mappedImage = mapImage(image) ?? getRequiredImageFallback()
        return {
          key: String(image.id),
          src: mappedImage.src,
          alt: mappedImage.alt,
        }
      }),
    }))}
    {...others}
  />
)

export default TabbedCarouselBlok
